<template>
    <section id="content">
        <div class="container">
            <div class="c-header">
                <h2>Change Password</h2>
            </div>

            <div class="card">
                <div class="card-body card-padding">
                    <form @submit.prevent="changePassword">
                        <div class="row">
                            <div class="col-md-6">

                                <div class="form-group">
                                    <label for="cPassword">Current Password</label>
                                    <input type="password" class="form-control" id="cPassword" v-model="password.cPassword" required>
                                </div>

                                <div class="form-group">
                                    <label for="nPassword">New Password</label>
                                    <input type="password" class="form-control" id="nPassword" v-model="password.nPassword" required>
                                </div>

                                <div class="form-group">
                                    <label for="rPassword">Repeat Password</label>
                                    <input type="password" class="form-control" id="rPassword" v-model="password.rPassword" required>
                                </div>

                                <div class="form-group">
                                    <button type="submit" class="btn btn-success btn-icon-text" :disabled="isProcessing">
                                        <i class="zmdi zmdi-save"></i>
                                        Change Password
                                        <span class="loader" v-if="isProcessing"></span>
                                    </button>
                                </div>

                            </div>
                            <div class="col-md-6">
                                
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
export default {
    data(){
        return {
            password: {
                userId: this.$store.state.user.userId,
                cPassword: "",
                nPassword: "",
                rPassword: "",
            },
            isProcessing: false,
        }
    },
    methods: {
        changePassword() {
            this.isProcessing = true
            this.$http
                .post("/users/change-password", this.password)
                .then(response => {
                    if (response.data.code === 200) {
                        this.$notify.success(response.data.reason)
                        this.password = {
                            userId: this.$store.state.user.userId,
                            cPassword: "",
                            nPassword: "",
                            rPassword: "",
                        }
                    } else {
                        this.$notify.error(response.data.reason)
                    }
                    this.isProcessing = false
                })
                .catch(error => {
                    this.$notify.error(error)
                    this.isProcessing = false
                })
        }
    }
}
</script>